import { PaginationDetails, ProductBundle } from "@apacta/sdk";

export class LoadBundleProducts {
    public static readonly type = "[Products] Load bundle products";

    constructor(public payload: ProductBundle[]) {}
}

export class LoadBundlePagination {
    public static readonly type = "[Products] Load bundle pagination";

    constructor(public payload: PaginationDetails) {}
}

export class ClearBundleProducts {
    public static readonly type = "[Products] Clear bundle products";
}
