import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Pagination } from "src/app/services/response-models/projects.response.model";
import { LastUsedProduct } from "src/app/services/response-models/product.response.model";
import { BasicProducts } from "./models/BasicProducts";
import { VendorProdcuts } from "./models/VendorProducts";
import { ExpenseLinesProducts } from "./models/ExpenseLinesProducts";
import {
    ClearBasicProducts,
    LoadBasicPagination,
    LoadBasicProducts,
} from "./actions/set-basic-products.action";
import {
    ClearVendorProducts,
    LoadVendorProducts,
    LoadVendorProductsPagination,
} from "./actions/set-vendor-products.action";
import {
    ClearExpenseLinesProducts,
    LoadExpenseLinesPagination,
    LoadExpenseLinesProducts,
} from "./actions/set-expense-products.action";
import { Injectable } from "@angular/core";
import {
    ClearFormsProducts,
    SetFormsProducts,
    SetFormsProductsPagination,
} from "./actions/set-forms-products.action";
import { ClearAllProductsOrdered, LoadAllProductsOrdered } from "./actions/set-all-products.action";
import { OrderedProduct } from "./models/ordered-product.model";
import { PaginationDetails, Product, ProductBundle } from "@apacta/sdk";
import {
    ClearBundleProducts,
    LoadBundlePagination,
    LoadBundleProducts,
} from "src/store/products/actions/set-bundle-products.action";
import { BundleProducts } from "src/store/products/models/BundleProducts";

export interface ProductsStateModel {
    basicProducts: Product[];
    basicProductsPagination: PaginationDetails;
    bundleProducts: ProductBundle[];
    bundleProductsPagination: PaginationDetails;
    vendorProducts: Product[];
    vendorProductsPagination: PaginationDetails;
    expenseLinesProducts: Product[];
    expenseLinesProductsPagination: PaginationDetails;
    formsProducts: LastUsedProduct[];
    formsProductsPagination: Pagination;
    orderedProducts: OrderedProduct[];
}

@State<ProductsStateModel>({
    name: "products",
    defaults: {
        basicProducts: [],
        basicProductsPagination: null,
        bundleProducts: [],
        bundleProductsPagination: null,
        vendorProducts: [],
        vendorProductsPagination: null,
        expenseLinesProducts: [],
        expenseLinesProductsPagination: null,
        formsProducts: [],
        formsProductsPagination: null,
        orderedProducts: [],
    },
})
@Injectable()
export class ProductsState {
    constructor() {}

    // Select All - deprecated, please remove - gaa
    /**
     * @deprecated
     */
    @Selector()
    public static getAllProducts(state: ProductsStateModel) {
        return state.basicProducts
            .concat(state.expenseLinesProducts)
            .concat(state.vendorProducts)
            .concat(state.bundleProducts);
    }

    // Select all products ordered
    @Selector()
    public static getOrderedProducts(state: ProductsStateModel) {
        return state.orderedProducts;
    }

    // Basic products pagination;
    @Selector()
    public static getBasicProductsAndPagination(state: ProductsStateModel): BasicProducts {
        const data: BasicProducts = {
            products: state.basicProducts,
            pagination: state.basicProductsPagination,
        };

        return data;
    }

    @Selector()
    public static getBasicProducts(state: ProductsStateModel) {
        return state.basicProducts;
    }

    @Selector()
    public static getBasicProductsPagination(state: ProductsStateModel) {
        return state.basicProductsPagination;
    }

    @Selector()
    public static getBundleProductsAndPagination(state: ProductsStateModel): BundleProducts {
        const data: BundleProducts = {
            products: state.bundleProducts,
            pagination: state.bundleProductsPagination,
        };

        return data;
    }

    @Selector()
    public static getBundleProducts(state: ProductsStateModel) {
        return state.bundleProducts;
    }

    @Selector()
    public static getBundleProductsPagination(state: ProductsStateModel) {
        return state.bundleProductsPagination;
    }

    @Selector()
    public static getVendorProducts(state: ProductsStateModel): Product[] {
        return state.vendorProducts;
    }

    @Selector()
    public static getVendorProductsAndPagination(state: ProductsStateModel): VendorProdcuts {
        const data: VendorProdcuts = {
            products: state.vendorProducts,
            pagination: state.vendorProductsPagination,
        };

        return data;
    }

    @Selector()
    public static getExpenseLinesProducts(state: ProductsStateModel): Product[] {
        return state.expenseLinesProducts;
    }

    @Selector()
    public static getExpenseLinesProductsAndPagination(
        state: ProductsStateModel
    ): ExpenseLinesProducts {
        const data: ExpenseLinesProducts = {
            products: state.expenseLinesProducts,
            pagination: state.expenseLinesProductsPagination,
        };

        return data;
    }

    // Basic Products;
    @Action(LoadBasicProducts)
    load(ctx: StateContext<ProductsStateModel>, { payload }: LoadBasicProducts) {
        const state = ctx.getState();

        ctx.patchState({
            basicProducts: [...state.basicProducts, ...payload],
        });
    }

    @Action(LoadBasicPagination)
    loadBasicPagination(ctx: StateContext<ProductsStateModel>, { payload }: LoadBasicPagination) {
        ctx.setState({ ...ctx.getState(), basicProductsPagination: payload });
    }

    @Action(LoadBundleProducts)
    loadBundle(ctx: StateContext<ProductsStateModel>, { payload }: LoadBundleProducts) {
        const state = ctx.getState();

        ctx.patchState({
            bundleProducts: [...state.bundleProducts, ...payload],
        });
    }

    @Action(LoadBundlePagination)
    loadBundlePagination(ctx: StateContext<ProductsStateModel>, { payload }: LoadBundlePagination) {
        ctx.setState({ ...ctx.getState(), bundleProductsPagination: payload });
    }

    // Vendor Products
    @Action(LoadVendorProducts)
    loadVendorProducts(ctx: StateContext<ProductsStateModel>, { payload }: LoadVendorProducts) {
        const state = ctx.getState();

        ctx.patchState({
            vendorProducts: [...state.vendorProducts, ...payload],
        });
    }

    @Action(LoadVendorProductsPagination)
    loadVendorProductsPagination(
        ctx: StateContext<ProductsStateModel>,
        { payload }: LoadVendorProductsPagination
    ) {
        ctx.setState({ ...ctx.getState(), vendorProductsPagination: payload });
    }

    // Expense lines products
    @Action(LoadExpenseLinesProducts)
    loadExpenseLinesProducts(
        ctx: StateContext<ProductsStateModel>,
        { payload }: LoadExpenseLinesProducts
    ) {
        const state = ctx.getState();

        ctx.patchState({
            expenseLinesProducts: [...state.expenseLinesProducts, ...payload],
        });
    }

    @Action(LoadExpenseLinesPagination)
    LoadExpenseLinesPagination(
        ctx: StateContext<ProductsStateModel>,
        { payload }: LoadExpenseLinesPagination
    ) {
        ctx.setState({ ...ctx.getState(), expenseLinesProductsPagination: payload });
    }

    // Forms Products
    @Action(SetFormsProducts)
    loadFormsProducts(ctx: StateContext<ProductsStateModel>, { payload }: SetFormsProducts) {
        ctx.setState({ ...ctx.getState(), formsProducts: payload });
    }

    @Action(SetFormsProductsPagination)
    loadFormsProductsPagination(
        ctx: StateContext<ProductsStateModel>,
        { payload }: SetFormsProductsPagination
    ) {
        ctx.setState({ ...ctx.getState(), formsProductsPagination: payload });
    }

    @Action(LoadAllProductsOrdered)
    loadAllProductsOrdered(
        ctx: StateContext<ProductsStateModel>,
        { payload }: LoadAllProductsOrdered
    ) {
        const state = ctx.getState();

        ctx.patchState({
            orderedProducts: [...state.orderedProducts, payload],
        });
    }

    // Clear
    @Action(ClearBasicProducts)
    clearBasic(ctx: StateContext<ProductsStateModel>) {
        ctx.setState({ ...ctx.getState(), basicProducts: [] });
    }

    @Action(ClearBundleProducts)
    clearBundle(ctx: StateContext<ProductsStateModel>) {
        ctx.setState({ ...ctx.getState(), bundleProducts: [] });
    }

    @Action(ClearVendorProducts)
    clearVendor(ctx: StateContext<ProductsStateModel>) {
        ctx.setState({ ...ctx.getState(), vendorProducts: [] });
    }

    @Action(ClearExpenseLinesProducts)
    clearExpenseLines(ctx: StateContext<ProductsStateModel>) {
        ctx.setState({ ...ctx.getState(), expenseLinesProducts: [] });
    }

    @Action(ClearFormsProducts)
    clearFormsProducts(ctx: StateContext<ProductsStateModel>) {
        ctx.setState({ ...ctx.getState(), formsProducts: [] });
    }

    @Action(ClearAllProductsOrdered)
    clearAllProducts(ctx: StateContext<ProductsStateModel>) {
        ctx.setState({ ...ctx.getState(), orderedProducts: [] });
    }
}
